import React from 'react';
import Helmet from 'react-helmet';
import { usePageContext } from 'store/PageProvider';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({
  seo_title,
  seo_og_title,
  seo_description,
  seo_og_description,
  seo_og_image,
  schemaorg,
  meta = [],
}) {
  const { lang, siteData, hreflang } = usePageContext();
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            siteUrl
          }
        }
      }
    `
  );

  if (!siteData) return null;

  const {
    seo_title: defaultTitle,
    seo_og_title: defaultOgTitle,
    seo_description: defaultDescription,
    seo_og_description: defaultOgDescription,
    seo_og_image: defaultOgImage,
    seo_twitter_site,
  } = siteData;

  const hasSchema = !!schemaorg?.text;
  const hasOgImage = !!seo_og_image?.url;
  const title = seo_title || seo_og_title || defaultTitle;
  const ogTitle = seo_og_title || seo_title || defaultOgTitle;
  const description = seo_description || seo_og_description || defaultDescription;
  const ogDescription = seo_og_description || seo_description || defaultOgDescription;
  const ogImage = hasOgImage ? seo_og_image : defaultOgImage;

  const metaImage = ogImage.url
    ? [
        {
          property: 'og:image',
          content: ogImage.url,
        },
        {
          property: 'og:image:width',
          content: ogImage.dimensions?.width || null,
        },
        {
          property: 'og:image:height',
          content: ogImage.dimensions?.height || null,
        },
      ]
    : [];

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      // titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: ogTitle,
        },
        {
          property: `og:description`,
          content: ogDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: ogTitle,
        },
        {
          name: `twitter:description`,
          content: ogDescription,
        },
        {
          name: `twitter:site`,
          content: seo_twitter_site,
        },
      ]
        .concat(metaImage)
        .concat(meta)}
    >
      {hreflang.map(({ lang, url }) => (
        <link
          key={url}
          rel="alternate"
          href={`${site.siteMetadata.siteUrl}${url}`}
          hrefLang={lang}
        />
      ))}
      {hasSchema && <script type="application/ld+json">{schemaorg.text}</script>}
    </Helmet>
  );
}

export default SEO;
