import React, { useEffect, useRef } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from 'components/SEO';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import linkResolver from 'core/linkResolver';
import { ContainerGrid } from 'components/Container';
import { useLifecycles } from 'react-use';
import { asCol, rem } from 'styles/utils';
import { rgba } from 'polished';
import { fontTitle } from 'styles/typography';
import media from 'styles/media';

const Booking = ({ data, pageContext }) => {
  const form = useRef(null);

  useEffect(() => {
    const $element = form.current;
    if ($element) {
      const script = document.createElement('script');

      script.src =
        'https://app.detailsdetails.eu/request/nuitsauvage/?artist_id=&lang=en&version=2';
      script.async = true;

      $element.appendChild(script);

      return () => {
        const $uis = [...document.querySelectorAll('body > [class*="ui-"]')];
        $uis.forEach(($ui) => $ui.remove());
        $element.removeChild(script);
        $element.innerHTML = '';
      };
    }
  }, []);

  if (!data?.prismicBooking?.data) return <h1>No data on home template</h1>;

  return (
    <Module>
      <SEO {...data.prismicBooking.data} />
      <HeaderBackground />
      <ContainerGrid>
        <Content>
          <div ref={form} />
        </Content>
      </ContainerGrid>
    </Module>
  );
};

const HeaderBackground = styled.div`
  position: relative;
  height: var(--header-height);
  background: url('/images/background.jpg') ${({ theme }) => theme.colors.black};
  background-size: cover;
  background-position: center top;

  &:after {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    background: ${({ theme }) => rgba(theme.colors.black, 0.3)};
  }
`;

const Content = styled.div`
  grid-column: 7 / span 24;
  padding: ${asCol(2.5)} 0;

  ${media.mobile`
    padding: ${asCol(6)} 0 ${asCol(6)};
  `}

  /* Global */
  .berlin3-body {
    font-family: 'source_code_pro', sans-serif;
    font-size: initial;
    line-height: ${({ theme }) => theme.lineHeight.base};
  }

  /* Header */
  .berlin3-header {
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    width: 100%;
    text-align: right;
    font-size: ${rem(16)};

    .berlin3-lang-manu {
      float: right;
      width: auto;
    }

    .berlin3-col-md-6.berlin3-col-md-offset-4 {
      margin-left: auto !important;
      width: auto;
    }

    ul.berlin3-lang > li > a {
      color: ${({ theme }) => theme.colors.black};
    }

    .berlin3-lang {
      text-align: right;
      display: flex;
      align-items: flex-start;
    }

    [style*='color: #f15a24'] {
      color: ${({ theme }) => theme.colors.black} !important;
    }

    .berlin3-badge.berlin3-bg_orange {
      background: none;
      font: inherit;
      font-size: inherit;
      line-height: inherit;
      font-weight: bold;
      text-decoration: underline;
      color: ${({ theme }) => theme.colors.black};
      padding: 0;
    }
  }

  /* Title */
  #berlin3-calender_info .berlin3-calenderinfo_text {
    ${fontTitle}
    font-size: ${rem(20)};
    line-height: ${({ theme }) => theme.lineHeight.base};
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.black};
    text-align: center;
    margin-bottom: ${asCol(1.25)};
  }

  /* Panel Toggle */
  .berlin3-panel-default {
    border: 0;
    margin-bottom: ${asCol(0.25)};

    & > .berlin3-panel-heading {
      background: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.white};
      border-radius: 0;
      border: 0;
      padding: ${asCol(0.5)} ${asCol(1)};
    }

    a:hover,
    a:focus {
      text-decoration: none;
    }
  }

  .berlin3-panel_title_style {
    ${fontTitle}
    font-size: ${rem(18)};
    line-height: ${({ theme }) => theme.lineHeight.base};
    color: ${({ theme }) => theme.colors.white};
    text-transform: uppercase;
  }

  .berlin3-panel-body {
    padding-bottom: ${asCol(0.75)};
  }

  /* Form group */
  .textbox {
    position: relative;
  }

  .berlin3-row.berlin3-form-group {
    background: none;
    margin: 0;

    & > div {
      display: block !important;
      width: 100% !important;
    }

    .textlabel {
      background: none;
      padding: ${asCol(0.75)} 0 ${asCol(0.5)};
    }

    .textbox input,
    .textbox select {
      box-sizing: border-box;
      height: ${asCol(1.75)};

      ${media.mobile`
        height: ${asCol(3.5)};
      `}
    }

    .textbox select {
      padding-right: ${asCol(1)};
    }

    .textbox input,
    .textbox select,
    .textbox textarea {
      padding-left: ${asCol(0.5)};
      border: 0;
      box-shadow: none;
      border-radius: 0;
      background: ${({ theme }) => theme.colors.whitesmoke};
    }
  }

  .berlin3-form-control {
    width: 98%;
  }

  .berlin3-correct-img,
  .berlin3-incorrect-img {
    position: absolute;
    right: ${rem(5)};
    top: 50%;
    transform: translateY(-50%);
    padding-top: 0;
  }

  /* Footer */
  .berlin3-sub {
    background: none;
    color: ${({ theme }) => theme.colors.black};
    padding: 0;

    [style*='color: #f15a24'],
    [style*='color: white'],
    [style*='color: #fff'] {
      color: ${({ theme }) => theme.colors.black} !important;
    }
  }

  #berlin3-press {
    display: none;
  }

  #berlin3-submit {
    position: relative;
    z-index: 10;

    .berlin3-col-lg-12 {
      margin: ${asCol(1)} 0;
      padding: 0;
    }

    & + div {
      float: none;
      display: block;

      & > div:first-child,
      & > div:last-child {
        display: none;
        float: none;
      }

      & > div:nth-child(2) {
        width: 100% !important;
        float: none;
      }
    }
  }

  #submit_contact {
    ${fontTitle}
    position: relative;
    display: flex;
    align-items: center;
    padding: ${rem(15)} ${asCol(1)};
    background: ${({ theme }) => theme.colors.blue};
    text-align: center;
    justify-content: center;
    text-transform: uppercase;
    border-radius: 0;
    font-weight: normal;
    line-height: ${({ theme }) => theme.lineHeight.base};
  }

  /* Trademark */
  .berlin3-footer_info {
    display: none;
  }

  /* OTHERS */
  .berlin3-header > div:first-child {
    display: none;
  }

  .berlin3-fixed_rightbutton {
    display: none !important;
  }

  .berlin3-btn {
    background: ${({ theme }) => theme.colors.blue};
    color: ${({ theme }) => theme.colors.white};
  }
`;
const Module = styled.div`
  ${ContainerGrid} {
    position: relative;
    background: ${({ theme }) => theme.colors.white};
  }
`;

export default withPrismicPreview(Booking, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
]);

export const pageQuery = graphql`
  query BookingQuery($uid: String!, $lang: String!) {
    prismicBooking(uid: { eq: $uid }, lang: { eq: $lang }) {
      _previewable
      id
      uid
      data {
        seo_title
        seo_og_title
        seo_description
        seo_og_description
        title {
          text
        }
      }
    }
  }
`;
