module.exports = function linkResolver({ uid, type, lang, link_type, url }) {
  if (link_type === 'Web') return url ? url : '/';
  if (!uid || !lang) return '/';

  const [locale] = lang.split('-');
  const isDefaultLocale = locale === 'en';

  switch (type) {
    case 'home':
      return isDefaultLocale ? `/` : `/${locale}/`;
    case '404':
      return isDefaultLocale ? `/404/` : `/${locale}/404/`;
    default:
      return isDefaultLocale ? `/${uid}/` : `/${locale}/${uid}/`;
  }
};
